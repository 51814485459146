/* istanbul ignore file */

import { RefObject, useCallback, useRef } from 'react';
import { createPortal } from 'react-dom';
import { defineMessages, useIntl } from 'react-intl';
import { getDisplayedPrices, getProductVariant, ProductCart } from '@slicekit/core';
import { useCart, useMaxQuantity } from '@slicekit/react';
import { useIsFeatureEnabled } from 'cb-wallet-data/FeatureManager/hooks/useIsFeatureEnabled';
import { getConfig } from 'cb-wallet-data/scw/libs/wagmi/config';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { Button } from 'wallet-cds-web/components/Button';
import { MiamiIllustration } from 'wallet-cds-web/components/MiamiIllustrationWrapper/MiamiIllustration';
import { Dropdown, DropdownRefProps } from '@cbhq/cds-web/dropdown';
import { useBreakpoints } from '@cbhq/cds-web/hooks/useBreakpoints';
import { Icon } from '@cbhq/cds-web/icons';
import { Box, HStack, VStack } from '@cbhq/cds-web/layout';
import { useToast } from '@cbhq/cds-web/overlays/useToast';
import { TextBody, TextCaption, TextLabel1, TextTitle3 } from '@cbhq/cds-web/typography';
import { useConnectButton } from ':dapp/components/ConnectButton/useConnectButton';
import { usePreferredAccount, WalletPreferenceFeature } from ':dapp/hooks/usePreferredAccount';
import { RoutesEnum } from ':dapp/utils/RoutesEnum';
import { ethUsd } from '../shop/allowedProducts';
import { defaultProductImage } from '../shop/index.page';
const messages = defineMessages({
  ocsBag: {
    defaultMessage: 'Bag',
    description: 'Bag with items in it'
  },
  ocsCheckoutShort: {
    defaultMessage: 'Checkout',
    description: 'Check out with your stuff!'
  },
  ocsSize: {
    defaultMessage: 'Size',
    description: 'Size for your item'
  },
  ocsQuantity: {
    defaultMessage: 'Quantity',
    description: 'Quantity for your item'
  },
  ocsMaxQuantity: {
    defaultMessage: 'Not enough stock for this quantity',
    description: 'Not enough stock for this quantity'
  },
  ocsEmptyBag: {
    defaultMessage: 'Nothing in your bag yet!',
    description: 'Lets fill up those bags!!'
  },
  ocsBagEmptySub: {
    defaultMessage: "Your bag is empty. Let's change that.",
    description: 'Lets fill up those bags!!'
  },
  ocsBagStartShopping: {
    defaultMessage: 'Start shopping',
    description: 'Woooohoooooooooooooooo'
  },
  ocsDEVELOPMENTONLYAddItem: {
    defaultMessage: 'DEVELOPMENTONLY Add item for testing',
    description: 'woooooooooooooooooooooooooooooooooooooooooooooo'
  },
  ocsItemRemovedToast: {
    defaultMessage: 'Item removed',
    description: 'An item has been removed'
  },
  ocsItemRemovedAddAgainToastAction: {
    defaultMessage: 'Add again',
    description: 'Oopsie, my bad, let us re-add that'
  }
});
const pointableStyle = {
  cursor: 'pointer'
};
type SingleTextLabelProps = {
  x: string;
  idx: number;
  dropdownName: string;
  selectionCallback: ((arg0: number) => void) | undefined;
};
function INTERNALSingleTextLabel(props: SingleTextLabelProps) {
  const clickHandler = useCallback(() => {
    if (props.selectionCallback) {
      props.selectionCallback(props.idx);
    }
  }, [props]);
  return <div className={"d2kqmtl"} onClick={clickHandler} key={`ocs-dropdown-x-${props.x}-on-dd-${props.dropdownName}`}>
      <TextBody as="p">{props.x}</TextBody>
    </div>;
}
export type DynamicTextDropdownProps = {
  labels: string[];
  uniqueName: string;
  selectionCallback?: (arg0: number) => void;
};
export function DynamicTextDropdown(props: DynamicTextDropdownProps) {
  const labels: string[] = props.labels ?? [];
  return <VStack spacingVertical={1}>
      {labels.map((x, idx) => {
      return <INTERNALSingleTextLabel x={x}
      // eslint-disable-next-line react/no-array-index-key
      key={`ocs-dynamic-text-dropdown-${props.uniqueName}-${idx}`} idx={idx} selectionCallback={props.selectionCallback} dropdownName={props.uniqueName} />;
    })}
    </VStack>;
}
export type RowDropdownProps = {
  title: string;
  selectedValue: string;
  possibleValues: string[];
  updateItem: (arg0: string) => void;
  uniqueKey?: string;
};
export function RowDropdow({
  title,
  possibleValues,
  selectedValue,
  uniqueKey,
  updateItem
}: RowDropdownProps) {
  const dropdownRef = useRef<DropdownRefProps>(null);
  const dropdownCB = useCallback((selectedIndex: number) => {
    dropdownRef.current?.closeMenu();
    updateItem(possibleValues[selectedIndex]);
  }, [updateItem, possibleValues]);
  return <Box justifyContent="space-between" width="100%">
      <TextBody as="p" color="foregroundMuted">
        {title}
      </TextBody>
      <Dropdown ref={dropdownRef} key={`ocs-lr-dropdown-${title}-init-${selectedValue}-pv-${JSON.stringify(possibleValues)}-uk-${uniqueKey}`} content={<DynamicTextDropdown labels={possibleValues} selectionCallback={dropdownCB} uniqueName={`dropdown-${uniqueKey}`} />}>
        <HStack alignItems="center" gap={1} style={pointableStyle}>
          <TextBody as="p" color="foregroundMuted">
            {selectedValue}
          </TextBody>
          <Icon name="caretDown" size="s" color="foreground" />
        </HStack>
      </Dropdown>
    </Box>;
}
const coverFit = {
  objectFit: 'cover' as const
};
export type BagItemletteProps = {
  product: ProductCart;
  dropdownRef?: RefObject<DropdownRefProps>;
};
export function BagItemlette({
  product,
  dropdownRef
}: BagItemletteProps) {
  const {
    formatMessage
  } = useIntl();
  const {
    updateCartProductQuantity,
    updateCartProductVariant,
    addToCart,
    removeFromCart,
    cart
  } = useCart();
  const {
    account
  } = usePreferredAccount({
    blockchainSymbol: 'ETH',
    feature: WalletPreferenceFeature.OCS
  });
  const toast = useToast();
  const selectedVariant = getProductVariant(product);
  const {
    data: maxQuantity
  } = useMaxQuantity(getConfig(), {
    product,
    buyer: account?.primaryAddress as `0x${string}` | undefined,
    selectedVariantId: product.externalVariantId,
    editMode: true
  });
  const updateItemSize = useCallback(async (arg0: string) => {
    const variant = product.externalProduct?.providerVariants.find(x => x.variant === arg0);
    updateCartProductVariant({
      product,
      variantId: variant!.id
    });
  }, [product, updateCartProductVariant]);
  const updateQuantity = useCallback((arg0: string) => {
    updateCartProductQuantity({
      product,
      quantity: Number(arg0)
    });
  }, [product, updateCartProductQuantity]);
  const deletedItemCommand = useCallback(() => {
    removeFromCart({
      product
    });
    function reopenDropdownOnClose() {
      if (dropdownRef?.current) {
        dropdownRef.current.openMenu();
      }
    }
    if (dropdownRef) {
      setTimeout(reopenDropdownOnClose, 10);
    }
    function reAddDeleted() {
      addToCart({
        product,
        quantity: product.quantity,
        variantId: product.externalVariantId
      });
      if (dropdownRef) {
        setTimeout(reopenDropdownOnClose, 10);
      }
    }
    toast.show(formatMessage(messages.ocsItemRemovedToast), {
      action: {
        label: formatMessage(messages.ocsItemRemovedAddAgainToastAction),
        onPress: reAddDeleted
      }
    });
  }, [formatMessage, toast, dropdownRef, removeFromCart, product, addToCart]);
  const {
    displayedFullPrice
  } = getDisplayedPrices({
    product,
    ethUsd
  });
  const quantitiesInDropdown = maxQuantity && Array.from({
    length: Math.min(maxQuantity, product.quantity + 10)
  }, (_, i) => String(i + 1));

  // remove from possible variants the others already in the cart and not availble for given quantity
  const possibleVariants = product.externalProduct?.providerVariants?.filter(variant => {
    const variantInCart = cart.find(p => p.externalVariantId === variant.id && selectedVariant.id !== variant.id);
    return !variantInCart && (variant.availableUnits !== null ? variant.availableUnits >= product.quantity : true);
  }).map(variant => variant.variant) || [];
  return <HStack width="100%" gap={2}>
      <div className={"dpcnf3h"}>
        <Image src={product.images?.[0] || defaultProductImage} fill style={coverFit} alt="product image" />
        <div className={"d18rt0g6"} onClick={deletedItemCommand}>
          <Icon name="trashCan" size="s" color="foreground" />
        </div>
      </div>
      <VStack flexGrow={1} gap={1}>
        <TextCaption as="p">{product.slicerName}</TextCaption>
        <TextBody as="p">{product.name}</TextBody>
        <TextBody as="p" color="foregroundMuted">
          {displayedFullPrice}
        </TextBody>
        {possibleVariants.length > 0 && <RowDropdow title={formatMessage(messages.ocsSize)} selectedValue={selectedVariant.variant} possibleValues={possibleVariants} uniqueKey={String(selectedVariant.id)} updateItem={updateItemSize} />}
        <RowDropdow title={formatMessage(messages.ocsQuantity)} selectedValue={product.quantity.toString()} possibleValues={quantitiesInDropdown as string[]} uniqueKey={`${product.dbId}-quantity-${product.externalVariantId || 'no-variant'}`} updateItem={updateQuantity} />
      </VStack>
    </HStack>;
}

// TODO: needs to be refactored into maybe some
// shopping cart file
// leaving it here for now until we get global application state
// integrated

type ShoppingCartContentProps = {
  dropdownRef: RefObject<DropdownRefProps>;
};
function ShoppingCartDropdownContent(props: ShoppingCartContentProps) {
  const {
    formatMessage
  } = useIntl();
  const {
    cart
  } = useCart();
  const {
    account: connectedAccount
  } = usePreferredAccount({
    blockchainSymbol: 'ETH',
    feature: WalletPreferenceFeature.OCS
  });
  const {
    buttonText,
    handleOpenModal
  } = useConnectButton({});
  if (cart.length > 0) {
    return <Box maxHeight="80vh" overflow="scroll">
        <VStack spacingTop={3} spacingStart={3} spacingEnd={3}>
          <TextLabel1 as="p" color="foregroundMuted">
            {formatMessage(messages.ocsBag)}
          </TextLabel1>

          <Box height="16px" minHeight="16px" />
          <VStack gap={4}>
            {cart.map((product, id) => {
            const key = `ocs-bag-item-lette-${product.name}-${product.slicerName}-${product.externalVariantId}-${product.quantity}-anticollide-idx-${id}`;
            return <BagItemlette key={key} product={product} dropdownRef={props.dropdownRef} />;
          })}
          </VStack>

          <Box height="24px" minHeight="24px" />

          <Box spacingBottom={3}>
            {connectedAccount?.primaryAddress ? <Button block href="/ocs/checkout">
                {formatMessage(messages.ocsCheckoutShort)}
              </Button> : <Button block onClick={handleOpenModal}>
                {buttonText}
              </Button>}
          </Box>
        </VStack>
      </Box>;
  }
  return <VStack spacing={3} alignItems="center" width={480} maxWidth="100%">
      <Box height="16px" />
      {/* component requires children for some inexplicable reason */}
      <MiamiIllustration illustration="ReferralsBonusMiami" isMiamiViceIllustration>
        <></>
      </MiamiIllustration>

      <Box height="16px" />
      <TextTitle3 as="p">{formatMessage(messages.ocsEmptyBag)}</TextTitle3>
      <Box height="8px" />
      <TextBody as="p" color="foregroundMuted">
        {formatMessage(messages.ocsBagEmptySub)}
      </TextBody>

      <Box height="32px" />

      <Button block href="/ocs/shop">
        {formatMessage(messages.ocsBagStartShopping)}
      </Button>
    </VStack>;
}
const dropdownPosition = {
  placement: 'top-end' as const,
  // 'top' results in being flush with right side of screen
  gap: 2 as const
};
const widgetPages = [RoutesEnum.OCS_PRODUCTS, RoutesEnum.OCS_SHOP];
/**
 * TODO: nav/shoppingCart not defined in IconButton class
 * TODO: check maxHeight property:
 *    setting maxHeight to {700} results it stretching with open space
 *    at the bottom sometimes for no reason...
 */
export function ShoppingCartWidget() {
  const {
    isPhone
  } = useBreakpoints();
  const {
    cart: cartData
  } = useCart();
  const isOcsShopEnabled = useIsFeatureEnabled('ocs_shop_tab');
  const router = useRouter();
  const pathname = router.pathname;
  const dropdownRef = useRef<DropdownRefProps>(null);
  const isOcsPage = widgetPages.some(page => {
    return !!pathname.startsWith(page);
  });
  if (typeof document === 'undefined') return null;
  if (cartData?.length > 0 && isOcsShopEnabled && isOcsPage) {
    return createPortal(<Box position="fixed" zIndex={8} bottom={isPhone ? 80 : 24} right={24} key={`ocs-cart-widget-k-${cartData.length}`}>
        <Dropdown contentPosition={dropdownPosition} content={<ShoppingCartDropdownContent dropdownRef={dropdownRef} />} maxHeight="100%" ref={dropdownRef} disableCloseOnOptionChange showOverlay>
          <div className={"d1a2ie6a"}>
            <svg width={46} height={46} viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M5.71387 9.52382H9.52339V34.2857C9.52339 34.2857 9.52339 34.9119 9.52339 35.2381C9.52339 37.868 11.6554 40 14.2853 40C16.9152 40 19.0472 37.868 19.0472 35.2381C19.0472 34.9119 19.0144 34.5934 18.9519 34.2857H26.7615C26.699 34.5934 26.6663 34.9119 26.6663 35.2381C26.6663 37.868 28.7982 40 31.4282 40C34.0581 40 36.1901 37.868 36.1901 35.2381C36.1901 32.6082 34.0581 30.4762 31.4282 30.4762H13.3329V27.6191H35.2377L39.0472 10.4762H13.3329V5.71429H5.71387V9.52382ZM31.4282 34.2857C31.9541 34.2857 32.3805 34.7121 32.3805 35.2381C32.3805 35.7641 31.9541 36.1905 31.4282 36.1905C30.9022 36.1905 30.4758 35.7641 30.4758 35.2381C30.4758 34.7121 30.9022 34.2857 31.4282 34.2857ZM13.3329 35.2381C13.3329 34.7121 13.7593 34.2857 14.2853 34.2857C14.8113 34.2857 15.2377 34.7121 15.2377 35.2381C15.2377 35.7641 14.8113 36.1905 14.2853 36.1905C13.7593 36.1905 13.3329 35.7641 13.3329 35.2381ZM13.3329 14.2857H34.2982L32.1818 23.8095H13.3329V14.2857Z" fill="#0A0B0D" />
            </svg>
            {cartData.length ? <>
                <div className={"d1kj4utj"} />
                {/* Border not working, so we just have to superimpose... */}
                <Box zIndex={11} position="absolute" top="14px" right="16px" background="backgroundAlternate" borderRadius="roundedFull" width="24px" height="24px">
                  <HStack alignItems="center" justifyContent="center" height="100%" width="100%">
                    <div className={"d1yp4kyj"}>
                      {/* NOTE: will break display if user has more than 1000 items in cart */}
                      {cartData.length}
                    </div>
                  </HStack>
                </Box>
              </> : null}
          </div>
        </Dropdown>
      </Box>, document.getElementById('portalRoot') ?? document.body);
  }
  return <></>;
}

require("./OCSCartWidget.linaria.module.css!=!../../../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./OCSCartWidget.tsx");